import { Center, Navigator, Parent } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function findParents(name: string, provider_id: string, center_id: string) {
  return {
    type: actionTypes.PARENT_FIND,
    name,
    provider_id,
    center_id,
  };
}

export function callbackFindParents(parentList: Parent[], errorCode: string | undefined) {
  return {
    type: actionTypes.PARENT_FIND_CALLBACK,
    parentList,
    errorCode,
  };
}

export function readParent(parent_id: string) {
  return {
    type: actionTypes.PARENT_READ,
    parent_id,
  };
}

export function callbackReadParent(parents: Parent[], navigator: Navigator, errorCode: string | undefined) {
  return {
    type: actionTypes.PARENT_READ_CALLBACK,
    parents,
    navigator,
    errorCode,
  };
}

export function readParentStatus(parent_id: string, partner_id: string) {
  return {
    type: actionTypes.PARENT_READ_STATUS,
    parent_id,
    partner_id,
  };
}

export function callbackReadParentStatus(lists: any[], seats: any[], tours: any[], matchedSeats: any[], savedCenters: { total: number, withOpenings: number, matchedSeats: number, notToured: number }, unseenCenters: Array<{center_id: string, cost_amount: number, cost_frequency: string, distance: number}>, marketWaitlistId: string | undefined, legupMarketId: string | undefined, tookSurvey: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PARENT_READ_STATUS_CALLBACK,
    lists,
    seats,
    tours,
    matchedSeats,
    savedCenters,
    unseenCenters,
    marketWaitlistId,
    legupMarketId,
    tookSurvey,
    errorCode,
  };
}

export function readFamilyCenters(parent_id: string, center_id: string) {
  return {
    type: actionTypes.PARENT_READ_CENTERS,
    parent_id,
    center_id,
  };
}

export function callbackReadFamilyCenters(waitlists: Center[], tours: Center[], favoriteOnly: Center[], centerData: any[], favorites: string[], errorCode: string | undefined) {
  return {
    type: actionTypes.PARENT_READ_CENTERS_CALLBACK,
    waitlists,
    tours,
    favoriteOnly,
    centerData,
    favorites,
    errorCode,
  };
}

export function addParent(parent: Parent, children: string[] | undefined) {
  return {
    type: actionTypes.PARENT_ADD,
    parent,
    children,
  };
}

export function callbackAddParent(parent_id: string, errorCode: string | undefined) {
  return {
    type: actionTypes.PARENT_ADD_CALLBACK,
    parent_id,
    errorCode,
  };
}

export function updateParent(parent: Parent) {
  return {
    type: actionTypes.PARENT_UPDATE,
    parent,
  };
}

export function callbackUpdateParent(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PARENT_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

export function updateParentSubsidies(parent_id: string, subsidies: string[] | undefined) {
  return {
    type: actionTypes.PARENT_UPDATE_SUBSIDIES,
    parent_id,
    subsidies,
  };
}

export function callbackUpdateParentSubsidies(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PARENT_UPDATE_SUBSIDIES_CALLBACK,
    success,
    errorCode,
  };
}

export function mergeChildren(parent_id: string, child_id_from: string, child_id_to: string) {
  return {
    type: actionTypes.PARENT_MERGE_CHILDREN,
    parent_id,
    child_id_from,
    child_id_to,
  };
}

export function callbackMergeChildren(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PARENT_MERGE_CHILDREN_CALLBACK,
    success,
    errorCode,
  };
}
