import { AgeGroup, Child, Seat, Tour, TourRequest } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface Waitlist {
  ageGroup: AgeGroup
  position: number
}

export function readChild(child_id: string) {
  return {
    type: actionTypes.CHILD_READ,
    child_id,
  };
}

export function callbackReadChild(child: Child, errorCode: string | undefined) {
  return {
    type: actionTypes.CHILD_READ_CALLBACK,
    child,
    errorCode,
  };
}

export function readChildWaitlists(provider_id: string | undefined, child_id: string) {
  return {
    type: actionTypes.CHILD_READ_WAITLISTS,
    provider_id,
    child_id,
  };
}

export function callbackReadChildWaitlists(waitlists: Waitlist[], errorCode: string | undefined) {
  return {
    type: actionTypes.CHILD_READ_WAITLISTS_CALLBACK,
    waitlists,
    errorCode,
  };
}

export function readChildWaitlistPosition(child_id: string, list_id: string) {
  return {
    type: actionTypes.CHILD_READ_WAITLIST_POSITION,
    child_id,
    list_id,
  };
}

export function callbackReadChildWaitlistPosition(positionDetails: any, errorCode: string | undefined) {
  return {
    type: actionTypes.CHILD_READ_WAITLIST_POSITION_CALLBACK,
    positionDetails,
    errorCode,
  };
}

export function readChildSeats(provider_id: string | undefined, child_id: string) {
  return {
    type: actionTypes.CHILD_READ_SEATS,
    provider_id,
    child_id,
  };
}

export function callbackReadChildSeats(seats: Seat[], errorCode: string | undefined) {
  return {
    type: actionTypes.CHILD_READ_SEATS_CALLBACK,
    seats,
    errorCode,
  };
}

export function readChildTours(provider_id: string | undefined, child_id: string) {
  return {
    type: actionTypes.CHILD_READ_TOURS,
    provider_id,
    child_id,
  };
}

export function callbackReadChildTours(tours: Tour[], tourRequests: TourRequest[], errorCode: string | undefined) {
  return {
    type: actionTypes.CHILD_READ_TOURS_CALLBACK,
    tours,
    tourRequests,
    errorCode,
  };
}

export function addChild(parent_id: string, child: Child) {
  return {
    type: actionTypes.CHILD_ADD,
    parent_id,
    child,
  };
}

export function callbackAddChild(child_id: string, errorCode: string | undefined) {
  return {
    type: actionTypes.CHILD_ADD_CALLBACK,
    child_id,
    errorCode,
  };
}

export function readChildNotes(child_id: string) {
  return {
    type: actionTypes.CHILD_READ_NOTES,
    child_id,
  };
}

export function callbackReadChildNotes(history: any[], errorCode: string | undefined) {
  return {
    type: actionTypes.CHILD_READ_NOTES_CALLBACK,
    history,
    errorCode,
  };
}

export function updateChild(child: Child) {
  return {
    type: actionTypes.CHILD_UPDATE,
    child,
  };
}

export function callbackUpdateChild(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CHILD_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}
